import { Box, HStack } from "@chakra-ui/react";
import { EpisodeType } from "../pages/SeriesViewer";
import { useEffect, useState } from "react";

type ProgressProps = {
  episodes: EpisodeType[];
  currentIndex: number;
  currentVideoTime: number;
  videoDuration: number;
  isImage: boolean;
};

export const Progress = ({
  episodes,
  currentIndex,
  currentVideoTime,
  videoDuration,
  isImage,
}: ProgressProps) => {
  const [progressBarWidth, setProgressBarWidth] = useState(0);

  useEffect(() => {
    if (currentVideoTime && videoDuration) {
      setProgressBarWidth((currentVideoTime / videoDuration) * 100);
    }

    if (isImage) {
      setProgressBarWidth(100);
    }
  }, [currentVideoTime, videoDuration, currentIndex, isImage]);

  return (
    <Box position="absolute" zIndex="2" p="2" w="full">
      <HStack w="full">
        {episodes.map((episode, index) => (
          <Box
            rounded="full"
            key={episode.id}
            h=".2rem"
            bg={currentIndex > index ? "white" : "rgba(255,255,255,0.5)"}
            flexGrow="1"
          >
            <Box
              h=".2rem"
              bg="white"
              transition="width 0.1s"
              w={currentIndex === index ? `${progressBarWidth}%` : "0%"}
              rounded="full"
            />
          </Box>
        ))}
      </HStack>
    </Box>
  );
};
