import { Box, HStack, Image } from "@chakra-ui/react";
import { Progress } from "./Progress";
import { EpisodeType } from "../pages/SeriesViewer";
import { useCallback, useEffect, useState } from "react";
import { VideoComponent } from "./VideoComponent";

type StoriesProps = {
  episodes: EpisodeType[];
  isMuted: boolean;
  setIsMuted: (isMuted: boolean) => void;
  isAutoPlay: boolean;
  setIsAutoPlay: (isAutoPlay: boolean) => void;
};

export const Stories = ({
  episodes,
  isMuted,
  setIsMuted,
  //   isAutoPlay,
  setIsAutoPlay,
}: StoriesProps) => {
  const [currentVideoTime, setCurrentVideoTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const onGoNext = useCallback(() => {
    if (currentIndex < episodes.length - 1) {
      setCurrentVideoTime(0);
      setCurrentIndex((prev) => prev + 1);
    }
  }, [currentIndex, episodes.length]);

  const onGoPrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (currentVideoTime >= videoDuration) {
      onGoNext();
    }
  }, [videoDuration, currentVideoTime, onGoNext]);

  const setTimer = useCallback(() => {
    const timer = setTimeout(() => {
      onGoNext();
    }, 5000);
    return () => clearTimeout(timer);
  }, [onGoNext]);

  useEffect(() => {
    if (!episodes[currentIndex].video) {
      setTimer();
    }
  }, [currentIndex, episodes, setTimer]);

  return (
    <Box bg="black" w="full" h="full" position="relative">
      <Progress
        episodes={episodes}
        currentIndex={currentIndex}
        currentVideoTime={currentVideoTime || 0}
        videoDuration={videoDuration || 10}
        isImage={!episodes[currentIndex].video}
      />
      {episodes.map((episode, index) => (
        <Box
          key={episode.id}
          w="full"
          h="full"
          position="absolute"
          zIndex={index === currentIndex ? 1 : 0}
        >
          {episode.video ? (
            <VideoComponent
              videoSrc={episode.video}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
              isAutoPlay={true}
              setIsAutoPlay={setIsAutoPlay}
              isFocused={index === currentIndex}
              onTimeUpdate={(currentTime) => setCurrentVideoTime(currentTime)}
              setVideoDuration={setVideoDuration}
            />
          ) : (
            <Image
              src={episode.image || ""}
              w="full"
              h="full"
              objectFit="cover"
              alt={episode.title}
            />
          )}
        </Box>
      ))}

      <HStack
        position="absolute"
        top="0"
        right="0"
        w="100%"
        h="100%"
        spacing="0"
      >
        <Box
          top="0"
          left="0"
          w="50%"
          h="100%"
          onClick={onGoPrevious}
          zIndex="3"
        />
        <Box top="0" right="0" w="50%" h="100%" onClick={onGoNext} zIndex="3" />
      </HStack>
    </Box>
  );
};
