import { Box, VStack } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { PlayIcon } from "../assets/icons/play";

type VideoComponentProps = {
  videoSrc: string;
  isFocused: boolean;
  isMuted: boolean;
  setIsMuted: (isMuted: boolean) => void;
  isAutoPlay: boolean;
  setIsAutoPlay: (isAutoPlay: boolean) => void;
  onTimeUpdate?: (currentTime: number) => void;
  setVideoDuration?: (duration: number) => void;
};

export const VideoComponent = ({
  videoSrc,
  isFocused,
  isMuted,
  isAutoPlay,
  setIsAutoPlay,
  onTimeUpdate,
  setVideoDuration,
}: VideoComponentProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (isFocused && videoRef.current && isAutoPlay) {
      videoRef.current?.play();
      setIsPlaying(true);
    }
    if (!isFocused && videoRef.current) {
      videoRef.current?.pause();
      setIsPlaying(false);
    }
  }, [videoRef, isFocused, isAutoPlay]);

  const onVideoClick = useCallback(() => {
    if (videoRef.current) {
      setIsAutoPlay(true);
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  }, [videoRef, setIsAutoPlay]);

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (videoRef.current) {
        onTimeUpdate?.(videoRef.current.currentTime);
      }
    };
    const handleVideoDuration = () => {
      if (videoRef.current) {
        setVideoDuration?.(videoRef.current.duration);
      }
    };

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("timeupdate", handleTimeUpdate);
      videoElement.addEventListener("loadedmetadata", handleVideoDuration);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
        videoElement.addEventListener("loadedmetadata", handleVideoDuration);
      }
    };
  }, [onTimeUpdate, setVideoDuration]);

  return (
    <VStack
      mx="auto"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      onClick={onVideoClick}
      position="relative"
    >
      <video
        ref={videoRef}
        muted={isMuted}
        loop
        preload="auto"
        controls={false}
        autoPlay={isAutoPlay}
        controlsList="nofullscreen nodownload noremoteplayback"
        playsInline
        style={{
          display: "block",
          objectFit: "cover",
          height: "100vh",
          width: "100%",
        }}
      >
        <source src={videoSrc + "#t=0.1"} />
      </video>
      {!isPlaying && (
        <Box
          position="fixed"
          p="4"
          backgroundColor="rgba(255,255,255,0.6)"
          rounded="full"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="20"
        >
          <PlayIcon />
        </Box>
      )}
    </VStack>
  );
};
